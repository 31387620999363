import { CountryCodeSupported } from "src/common/country";

export interface AddOnPricingByGeo {
  [key: string]: {
    data: {
      standard: {
        merchant_name_on_payer_statement?: string;
        custom_checkout_and_payer_notifications?: string;
      };
      advanced: {
        merchant_name_on_payer_statement?: string;
        custom_checkout_and_payer_notifications?: string;
      };
      pro: {
        merchant_name_on_payer_statement?: string;
        custom_checkout_and_payer_notifications?: string;
      };
    };
  };
}

export interface AddOnPricing {
  data: {
    standard: {
      merchant_name_on_payer_statement?: string;
      custom_checkout_and_payer_notifications?: string;
    };
    advanced: {
      merchant_name_on_payer_statement?: string;
      custom_checkout_and_payer_notifications?: string;
    };
    pro: {
      merchant_name_on_payer_statement?: string;
      custom_checkout_and_payer_notifications?: string;
    };
  };
}

export const CORE_GEO_PRICING: AddOnPricingByGeo = {
  [CountryCodeSupported.GB]: {
    data: {
      standard: {
        merchant_name_on_payer_statement: "£50",
        custom_checkout_and_payer_notifications: "N/A",
      },
      advanced: {
        merchant_name_on_payer_statement: "£50",
        custom_checkout_and_payer_notifications: "£150",
      },
      pro: {
        merchant_name_on_payer_statement: "£50",
        custom_checkout_and_payer_notifications: "£150",
      },
    },
  },
  [CountryCodeSupported.DE]: {
    data: {
      standard: {
        merchant_name_on_payer_statement: "€50",
        custom_checkout_and_payer_notifications: "N/A",
      },
      advanced: {
        merchant_name_on_payer_statement: "€50",
        custom_checkout_and_payer_notifications: "€200",
      },
      pro: {
        merchant_name_on_payer_statement: "€50",
        custom_checkout_and_payer_notifications: "€200",
      },
    },
  },
  [CountryCodeSupported.AT]: {
    data: {
      standard: {
        merchant_name_on_payer_statement: "€50",
        custom_checkout_and_payer_notifications: "N/A",
      },
      advanced: {
        merchant_name_on_payer_statement: "€50",
        custom_checkout_and_payer_notifications: "€200",
      },
      pro: {
        merchant_name_on_payer_statement: "€50",
        custom_checkout_and_payer_notifications: "€200",
      },
    },
  },
  [CountryCodeSupported.US]: {
    data: {
      standard: {
        merchant_name_on_payer_statement: "$25",
        custom_checkout_and_payer_notifications: "N/A",
      },
      advanced: {
        merchant_name_on_payer_statement: "$25",
        custom_checkout_and_payer_notifications: "$50",
      },
      pro: {
        merchant_name_on_payer_statement: "$25",
        custom_checkout_and_payer_notifications: "$50",
      },
    },
  },
  [CountryCodeSupported.CA]: {
    data: {
      standard: {
        merchant_name_on_payer_statement: "$75",
        custom_checkout_and_payer_notifications: "N/A",
      },
      advanced: {
        merchant_name_on_payer_statement: "$75",
        custom_checkout_and_payer_notifications: "$275",
      },
      pro: {
        merchant_name_on_payer_statement: "$75",
        custom_checkout_and_payer_notifications: "$275",
      },
    },
  },
  [CountryCodeSupported.AU]: {
    data: {
      standard: {
        merchant_name_on_payer_statement: "$100",
        custom_checkout_and_payer_notifications: "N/A",
      },
      advanced: {
        merchant_name_on_payer_statement: "$100",
        custom_checkout_and_payer_notifications: "$250",
      },
      pro: {
        merchant_name_on_payer_statement: "$100",
        custom_checkout_and_payer_notifications: "$250",
      },
    },
  },
  [CountryCodeSupported.NZ]: {
    data: {
      standard: {
        merchant_name_on_payer_statement: "$100",
        custom_checkout_and_payer_notifications: "N/A",
      },
      advanced: {
        merchant_name_on_payer_statement: "$100",
        custom_checkout_and_payer_notifications: "$300",
      },
      pro: {
        merchant_name_on_payer_statement: "$100",
        custom_checkout_and_payer_notifications: "$300",
      },
    },
  },
  [CountryCodeSupported.SE]: {
    data: {
      standard: {
        merchant_name_on_payer_statement: "500 kr",
        custom_checkout_and_payer_notifications: "N/A",
      },
      advanced: {
        merchant_name_on_payer_statement: "500 kr",
        custom_checkout_and_payer_notifications: "2000 kr",
      },
      pro: {
        merchant_name_on_payer_statement: "500 kr",
        custom_checkout_and_payer_notifications: "2000 kr",
      },
    },
  },
  [CountryCodeSupported.DK]: {
    data: {
      standard: {
        merchant_name_on_payer_statement: "500 kr.",
        custom_checkout_and_payer_notifications: "N/A",
      },
      advanced: {
        merchant_name_on_payer_statement: "500 kr.",
        custom_checkout_and_payer_notifications: "1500 kr.",
      },
      pro: {
        merchant_name_on_payer_statement: "500 kr.",
        custom_checkout_and_payer_notifications: "1500 kr.",
      },
    },
  },
};
// other Euro countries excl Germany and Austria
export const NON_CORE_EURO_GEO_PRICING: AddOnPricing = {
  data: {
    standard: {
      merchant_name_on_payer_statement: "€50",
      custom_checkout_and_payer_notifications: "N/A",
    },
    advanced: {
      merchant_name_on_payer_statement: "€50",
      custom_checkout_and_payer_notifications: "€200",
    },
    pro: {
      merchant_name_on_payer_statement: "€50",
      custom_checkout_and_payer_notifications: "€200",
    },
  },
};

export const REST_OF_WORLD_PRICING: AddOnPricing = {
  data: {
    standard: {
      merchant_name_on_payer_statement: "€50",
      custom_checkout_and_payer_notifications: "N/A",
    },
    advanced: {
      merchant_name_on_payer_statement: "€50",
      custom_checkout_and_payer_notifications: "€200",
    },
    pro: {
      merchant_name_on_payer_statement: "€50",
      custom_checkout_and_payer_notifications: "€200",
    },
  },
};
