import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  Icon,
  JustifyContent,
  P,
  TypePreset,
  Visibility,
} from "@gocardless/flux-react";

interface RewardListItemProps {
  isUnlocked: boolean;
  title: string | React.ReactNode;
  rewardDescription: string | React.ReactNode;
}

export const RewardListItem: React.FC<RewardListItemProps> = ({
  isUnlocked,
  title,
  rewardDescription,
}) => (
  <Box
    width="100%"
    layout="flex"
    alignItems={AlignItems.Center}
    gutterH={1}
    justifyContent={JustifyContent.SpaceBetween}
  >
    <Box layout="flex" alignItems={AlignItems.Center}>
      <Icon
        name={Glyph.TickCircleLight}
        color={isUnlocked ? Color.Ultraviolet_700 : ColorPreset.IconOnDark_02}
        size="30px"
      />
      <Box spaceBefore={1} spaceAfter={1}>
        <P preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
          {title}
        </P>
        <Visibility visible={["block", null, "none"]}>
          <P
            preset={TypePreset.Body_01}
            color={ColorPreset.TextOnLight_02}
            size={2}
          >
            {rewardDescription}
          </P>
        </Visibility>
      </Box>
    </Box>
    <Visibility visible={["none", null, "block"]}>
      <P
        preset={TypePreset.Body_01}
        color={ColorPreset.TextOnLight_02}
        size={2}
      >
        {rewardDescription}
      </P>
    </Visibility>
  </Box>
);
