enum AccountSetupStep {
  SetUpYourAccount = "set_up_your_account",
  CreateYourAccount = "create_your_account",
  GetStartedWithPayments = "get_started_with_payments",
  GetYourFirstPayout = "get_your_first_payout",
  EnableTwoStepAuthentication = "enable_two_step_authentication",
  CollectYourFirstPayment = "collect_your_first_payment",
  GetPaidEffortlessly = "get_paid_effortlessly",
  KccChecklist = "kcc_checklist",
}

export default AccountSetupStep;
