import {
  useOrganisationUpdateSelf,
  useOrganisationSelf,
} from "@gocardless/api/dashboard/organisation";
import {
  OrganisationPreferences,
  OrganisationUpdateSelfResponseBody,
} from "@gocardless/api/dashboard/types";
import { useAccessToken } from "src/common/authorisation";

/*
 * We fetch and update the organisation preferences by using the
 * organisation self get and update endpoint.
 *
 */

export interface OrganisationPreferencesProps {
  preferences: OrganisationPreferences;
  usage_intent?: string | null;
}
interface UseOrganisationPreferences {
  organisation_preferences?: OrganisationPreferences;
  updateOrganisationPreferences: (
    props: OrganisationPreferencesProps
  ) => Promise<OrganisationUpdateSelfResponseBody | undefined>;
}

export const useOrganisationPreferences = (
  onSuccess = () => {}
): UseOrganisationPreferences => {
  const [accessToken] = useAccessToken();
  const { data: organisation, revalidate } = useOrganisationSelf(
    accessToken?.links?.organisation || null
  );
  const [updateOrganisation] = useOrganisationUpdateSelf({
    onSuccess: () => {
      revalidate().then(() => onSuccess());
    },
  });

  const organisation_preferences =
    organisation?.organisations?.organisation_preferences;

  const updateOrganisationPreferences = ({
    preferences,
    usage_intent,
  }: OrganisationPreferencesProps) =>
    updateOrganisation({
      organisation_preferences: preferences,
      usage_intent:
        usage_intent || organisation?.organisations?.usage_intent || null,
    });

  return {
    organisation_preferences,
    updateOrganisationPreferences,
  };
};
