import { useCreditorRestrictionStatusList } from "@gocardless/api/dashboard/creditor-restriction-status";
import { usePaymentList } from "@gocardless/api/dashboard/payment";
import {
  SubscriptionListQueryParamsInclude,
  PaymentStatusEnum,
  AuthenticationFactorsStatus,
  FpjGetStartedType,
  UserResource,
  CreditorsVerificationStatus,
  ReferenceType,
} from "@gocardless/api/dashboard/types";
import { useOrganisationPreferences } from "src/queries/organisationPreferences";
import { useAuthenticationFactorList } from "@gocardless/api/dashboard/authentication-factor";
import { FirstPayoutDialogVariant } from "src/components/routes/SetupPayments/home/FirstPayout/types";
import { useCustomerList } from "@gocardless/api/dashboard/customer";
import _ from "lodash";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useOrganisation } from "src/queries/organisation";
import { useVerificationStatus } from "src/common/hooks/useVerificationStatus";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useActivationDiscount } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";
import { LowRiskOnboardingVariant } from "src/components/routes/SetupPayments/common/type";
import { useCollectionsPermitted } from "src/components/routes/SetupPayments/common/hooks/useCollectionsPermitted";
import { useActivationDiscountCanExtend } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscountCanExtend";

import AccountSetupStep from "./AccountSetupStep";
import { useKccChecklistDialog } from "./KccChecklistDialog/useKccChecklistDialog";

export interface UseAccountSetup {
  getStartedType?: FpjGetStartedType;
  steps: Partial<Record<AccountSetupStep, boolean>>;
  loaded: boolean;
  value: number;
  max: number;
  firstPayoutVariant: FirstPayoutDialogVariant;
  hasCustomer: boolean;
  hasPayment: boolean;
  user: UserResource | null;
  organisationName: string;
  hasCloseGreatWorkBanner: boolean;
  closeBanner: () => void;
  completed: boolean;
  hasDiscountExtension: boolean;
  canExtendDiscount: boolean;
  status: CreditorsVerificationStatus;
  lowRiskOnboardingVariant: LowRiskOnboardingVariant;
  kccVariantEnabled: boolean;
  useRevampedLayout: boolean;
  preActiveReportingEnabled: boolean;
}

const getVariant = (
  getStartedType: FpjGetStartedType,
  has_payout: boolean,
  has_payment: boolean
) => {
  switch (getStartedType) {
    case FpjGetStartedType.App:
      return FirstPayoutDialogVariant.App;
    case FpjGetStartedType.Api:
      return FirstPayoutDialogVariant.Api;
    default:
      if (has_payout) {
        return FirstPayoutDialogVariant.DashboardWithPayout;
      }
      if (has_payment) {
        return FirstPayoutDialogVariant.DashboardWithPayment;
      }
      return FirstPayoutDialogVariant.DashboardNoPayment;
  }
};

const getLowRiskOnboardingVariant = (
  cpvVariantEnabled: boolean,
  collectionsEnabled = false
) =>
  cpvVariantEnabled
    ? collectionsEnabled
      ? LowRiskOnboardingVariant.Eligible
      : LowRiskOnboardingVariant.NotEligible
    : LowRiskOnboardingVariant.Disabled;

const variantSteps = (
  variant: LowRiskOnboardingVariant,
  kccVariantEnabled: boolean,
  steps: Record<AccountSetupStep, boolean>
) => {
  if (kccVariantEnabled) {
    switch (variant) {
      case LowRiskOnboardingVariant.Eligible:
        // KCC checklist replaces the "Get paid effortlessly" step
        return _.pick(steps, [
          AccountSetupStep.CreateYourAccount,
          AccountSetupStep.GetStartedWithPayments,
          AccountSetupStep.CollectYourFirstPayment,
          AccountSetupStep.KccChecklist,
        ]);
      case LowRiskOnboardingVariant.NotEligible:
        // KCC checklist is added as a fifth step
        return _.pick(steps, [
          AccountSetupStep.CreateYourAccount,
          AccountSetupStep.SetUpYourAccount,
          AccountSetupStep.GetStartedWithPayments,
          AccountSetupStep.GetYourFirstPayout,
          AccountSetupStep.KccChecklist,
        ]);
      default:
        // KCC checklist replaces the "Enable two-factor authentication" step (it's a sub-step inside the dialog)
        return _.pick(steps, [
          AccountSetupStep.SetUpYourAccount,
          AccountSetupStep.GetStartedWithPayments,
          AccountSetupStep.GetYourFirstPayout,
          AccountSetupStep.KccChecklist,
        ]);
    }
  }

  switch (variant) {
    case LowRiskOnboardingVariant.Eligible:
      return _.pick(steps, [
        AccountSetupStep.CreateYourAccount,
        AccountSetupStep.GetStartedWithPayments,
        AccountSetupStep.CollectYourFirstPayment,
        AccountSetupStep.GetPaidEffortlessly,
      ]);
    case LowRiskOnboardingVariant.NotEligible:
      return _.pick(steps, [
        AccountSetupStep.CreateYourAccount,
        AccountSetupStep.SetUpYourAccount,
        AccountSetupStep.GetStartedWithPayments,
        AccountSetupStep.GetYourFirstPayout,
      ]);
    default:
      return _.pick(steps, [
        AccountSetupStep.SetUpYourAccount,
        AccountSetupStep.GetStartedWithPayments,
        AccountSetupStep.GetYourFirstPayout,
        AccountSetupStep.EnableTwoStepAuthentication,
      ]);
  }
};

const getSteps = (
  getStartedType: FpjGetStartedType,
  lowRiskOnboardingVariant: LowRiskOnboardingVariant,
  kccVariantEnabled: boolean,
  steps: Record<AccountSetupStep, boolean>
) => {
  const baseSteps = variantSteps(
    lowRiskOnboardingVariant,
    kccVariantEnabled,
    steps
  );

  const isAppOrApi =
    getStartedType === FpjGetStartedType.App ||
    getStartedType === FpjGetStartedType.Api;

  return isAppOrApi
    ? _.omit(baseSteps, AccountSetupStep.GetStartedWithPayments)
    : baseSteps;
};

export const useAccountSetup = (): UseAccountSetup => {
  const { isVariationOn: cpvVariantEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_COLLECTIONS_PRE_VERIFICATION_TOGGLE,
  });
  const { isVariationOn: kccVariantEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_KEEP_COLLECTING_CONFIDENTLY,
  });
  const { isVariationOn: preActiveReportingEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_PREACTIVE_MERCHANTS_REPORTING,
  });

  const { collectionsEnabled } = useCollectionsPermitted();
  const lowRiskOnboardingVariant = getLowRiskOnboardingVariant(
    cpvVariantEnabled,
    collectionsEnabled
  );

  const { data: restrictions } = useCreditorRestrictionStatusList();
  const { data: user } = useUserShowSelf();
  const organisation = useOrganisation();

  const { organisation_preferences, updateOrganisationPreferences } =
    useOrganisationPreferences();

  const { data: payouts } = usePaymentList({
    include: SubscriptionListQueryParamsInclude.Customer,
    status: PaymentStatusEnum.PaidOut,
    limit: 1,
  });

  const { data: collectedPayments } = usePaymentList({
    include: ReferenceType.Customer,
    status: PaymentStatusEnum.Confirmed,
  });

  const { data: payments } = usePaymentList({
    limit: 1,
  });
  const { data: customers } = useCustomerList({
    limit: 1,
  });

  const { data: auth } = useAuthenticationFactorList({
    is_default: true,
    status: AuthenticationFactorsStatus.Verified,
  });

  const { firstPayoutDiscount } = useActivationDiscount();
  const { canExtendDiscount } = useActivationDiscountCanExtend();

  const getStartedType =
    organisation_preferences?.fpj_get_started_type ||
    FpjGetStartedType.Dashboard;

  const { status } = useVerificationStatus();

  const { allStepsCompleted } = useKccChecklistDialog();

  if (
    !restrictions?.creditor_restriction_statuses ||
    !user?.users ||
    !organisation_preferences ||
    !payouts?.payments ||
    !auth?.authentication_factors ||
    !organisation
  ) {
    return {
      getStartedType,
      steps: getSteps(
        getStartedType,
        lowRiskOnboardingVariant,
        kccVariantEnabled,
        {
          [AccountSetupStep.CreateYourAccount]: true,
          [AccountSetupStep.SetUpYourAccount]: false,
          [AccountSetupStep.GetStartedWithPayments]: false,
          [AccountSetupStep.CollectYourFirstPayment]: false,
          [AccountSetupStep.GetPaidEffortlessly]: false,
          [AccountSetupStep.GetYourFirstPayout]: false,
          [AccountSetupStep.EnableTwoStepAuthentication]: false,
          [AccountSetupStep.KccChecklist]: false,
        }
      ),
      firstPayoutVariant: FirstPayoutDialogVariant.DashboardNoPayment,
      hasCustomer: false,
      hasPayment: false,
      loaded: false,
      value: 0,
      max: 0,
      user: null,
      organisationName: "",
      hasCloseGreatWorkBanner: false,
      closeBanner: () => {},
      completed: false,
      hasDiscountExtension: false,
      canExtendDiscount: !!canExtendDiscount,
      status,
      lowRiskOnboardingVariant: LowRiskOnboardingVariant.Disabled,
      kccVariantEnabled,
      useRevampedLayout: false,
      preActiveReportingEnabled: false,
    };
  }

  const hasSeenFirstPayment =
    !!organisation_preferences.fpj_payment_sharing_done;
  const hasPayout = payouts.payments.length > 0;
  const has2FA = auth.authentication_factors.length > 0;
  const hasPayment = payments?.payments ? payments?.payments.length > 0 : false;
  const hasCustomer = customers?.customers
    ? customers?.customers.length > 0
    : false;
  const hasCollectedPayments = collectedPayments?.payments
    ? collectedPayments?.payments?.length > 0
    : false;
  const hasCollectedThreePayments = collectedPayments?.payments
    ? collectedPayments?.payments?.length > 2
    : false;

  const variant = getVariant(
    getStartedType ?? FpjGetStartedType.Dashboard,
    hasPayout,
    hasPayment
  );

  const steps = getSteps(
    getStartedType,
    lowRiskOnboardingVariant,
    kccVariantEnabled,
    {
      [AccountSetupStep.SetUpYourAccount]:
        status === CreditorsVerificationStatus.Successful ||
        status === CreditorsVerificationStatus.InReview,
      [AccountSetupStep.GetStartedWithPayments]: hasSeenFirstPayment,
      [AccountSetupStep.GetYourFirstPayout]: hasPayout,
      [AccountSetupStep.EnableTwoStepAuthentication]: has2FA,
      [AccountSetupStep.CreateYourAccount]: true,
      [AccountSetupStep.CollectYourFirstPayment]: hasCollectedPayments,
      [AccountSetupStep.GetPaidEffortlessly]: hasCollectedThreePayments,
      [AccountSetupStep.KccChecklist]: allStepsCompleted,
    }
  );

  const value = Object.values(steps).filter((v) => v).length;
  const max = Object.values(steps).length;

  const closeBanner = () => {
    if (!organisation_preferences?.fpj_get_started_completed_hidden) {
      updateOrganisationPreferences({
        preferences: {
          fpj_get_started_completed_hidden: true,
        },
      });
    }
  };

  return {
    getStartedType,
    steps,
    hasCustomer,
    hasPayment,
    firstPayoutVariant: variant,
    loaded: true,
    value,
    max,
    user: user.users,
    organisationName: organisation.name ?? "",
    hasCloseGreatWorkBanner:
      !!organisation_preferences.fpj_get_started_completed_hidden,
    closeBanner,
    completed: value === max,
    hasDiscountExtension: !!firstPayoutDiscount,
    canExtendDiscount: !!canExtendDiscount,
    status,
    lowRiskOnboardingVariant: lowRiskOnboardingVariant,
    kccVariantEnabled,
    useRevampedLayout: cpvVariantEnabled || preActiveReportingEnabled,
    preActiveReportingEnabled: preActiveReportingEnabled,
  };
};
