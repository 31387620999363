import { useCallback, useState } from "react";
import { getItem, setItem } from "src/common/local-storage/local-storage";

export const useLocalStorage = (
  key: string,
  initialValue: object | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [any, (value: object | string) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = getItem(key);
    if (item) {
      return item;
    }

    return initialValue;
  });

  const setValue = useCallback(
    (value: object | string) => {
      setStoredValue(value);
      setItem(key, value);
    },
    [key]
  );

  return [storedValue, setValue];
};
