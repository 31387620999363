import { PropsWithChildren, ReactNode } from "react";
import { useAccountSetup } from "src/components/routes/SetupPayments/home/useAccountSetup";
import { useOptimizelyAttributes } from "src/technical-integrations/optimizely/useOptimizelyAttributes";
import { Role } from "src/common/environments";
import { isRole } from "src/common/config";

export interface SetupPaymentsEnabledProps
  extends PropsWithChildren<{ defaultNode: ReactNode }> {}

export const SetupPaymentsEnabled = ({
  children,
  defaultNode,
}: SetupPaymentsEnabledProps) => {
  const oa = useOptimizelyAttributes();
  const { completed } = useAccountSetup();
  const isSandboxEnvironment = isRole(Role.Sandbox);

  if (!oa) return null;

  // exclude sandbox, sales_served, connect and payment provider organisations,
  // and off-board those who are activated, are older than 30 days, or who have
  // completed all their account setup steps.
  const enabled =
    !isSandboxEnvironment &&
    oa.account_type !== "sales_served" &&
    oa.connected_app_id === "" &&
    !oa.is_payment_provider &&
    (!oa.activated ||
      (oa.activated && oa.account_age_in_days < 30 && !completed));
  return <>{enabled ? children : defaultNode}</>;
};
