import {
  CreditorRestrictionStatusListResponseBody,
  useCreditorRestrictionStatusList,
} from "@gocardless/api/dashboard/creditor-restriction-status";
import { useAPIResponseInterface } from "@gocardless/api/utils/use-api";

export interface UseCreditorRestrictionStatus
  extends useAPIResponseInterface<CreditorRestrictionStatusListResponseBody> {
  isUnderReview: boolean;
  isUndergoingOnboardingVerifications: boolean;
  isUndergoingInitialOnboardingVerifications: boolean;
}

export const useCreditorRestrictionStatus =
  (): UseCreditorRestrictionStatus => {
    const res = useCreditorRestrictionStatusList();
    const { data } = res;
    return {
      ...res,
      isUnderReview: data?.creditor_restriction_statuses?.under_review ?? false,
      isUndergoingOnboardingVerifications:
        data?.creditor_restriction_statuses
          ?.undergoing_onboarding_verifications ?? false,
      isUndergoingInitialOnboardingVerifications:
        data?.creditor_restriction_statuses
          ?.undergoing_initial_onboarding_verifications ?? false,
    };
  };
