import { FC } from "react";
import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  FontWeight,
  HoverEffect,
  PlainButton,
  PlainLink,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ToTranslate } from "src/components/i18n";
import { DISCOUNT_EXTENSION_LENGTH } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
/* eslint-disable import/no-unresolved */
import Illustration30DaysFree from "src/assets/png/illustration-30daysfree@2x.png";
/* eslint-enable import/no-unresolved */

interface ExtendDiscountBoxProps {
  collectionsEnabled?: boolean;
  openRewardsDialog?: () => void;
}

const ExtendDiscountBox: FC<ExtendDiscountBoxProps> = ({
  collectionsEnabled,
  openRewardsDialog = () => {},
}) => {
  const { isVariationOn: isRewardsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_REWARDS_AND_BOOSTS,
  });

  const learnMoreLink = (
    <PlainButton
      textDecoration="underline"
      effect={HoverEffect.TextDecoration}
      onClick={openRewardsDialog}
    >
      <ToTranslate>Learn more</ToTranslate>
    </PlainButton>
  );

  const viewTermsLink = (
    <PlainLink
      href={
        collectionsEnabled
          ? "https://gocardless.com/g/no-fee-terms-lro/"
          : "https://gocardless.com/g/no-fee-terms-fpj/"
      }
      target="_blank"
      rel="noreferrer"
      textDecoration="underline"
      effect={HoverEffect.TextDecoration}
    >
      <Trans id="extend-discount.dialog.view-terms">View terms</Trans>
    </PlainLink>
  );

  const headerText = (() => {
    if (isRewardsEnabled) {
      return <ToTranslate>Unlock extra days without fees</ToTranslate>;
    }

    return (
      <Trans id="extend-discount.dialog.body-title">
        Get 30 extra days without fees!
      </Trans>
    );
  })();

  const bodyText = (() => {
    if (isRewardsEnabled) {
      return (
        <ToTranslate>
          Unlock up to 60 extra days without fees for adding a first customer
          and collecting a first payment in your first 30 days. {learnMoreLink}
        </ToTranslate>
      );
    }

    if (collectionsEnabled) {
      return (
        <>
          <Trans id="extend-discount.dialog.body-desc.cpv-variant">
            Collect your first payment within your first{" "}
            {DISCOUNT_EXTENSION_LENGTH} days and we&apos;ll extend your no-fee
            period by 30 days! Please note: the discount will be applied the
            following day.
          </Trans>{" "}
          {viewTermsLink}
        </>
      );
    } else {
      return (
        <>
          <Trans id="extend-discount.dialog.body-desc">
            Get paid out to your bank account in your first{" "}
            {DISCOUNT_EXTENSION_LENGTH} days and we&apos;ll extend your no-fee
            period by 30 days! Please note: the discount will be applied the
            following day.
          </Trans>{" "}
          {viewTermsLink}
        </>
      );
    }
  })();

  return (
    <Box
      bg={
        isRewardsEnabled ? Color.Ultraviolet_50 : ColorPreset.BackgroundLight_00
      }
      gutterH={1.5}
      gutterV={1.5}
      layout="flex"
      flexDirection={["column", "row"]}
      alignItems={AlignItems.Center}
    >
      <img
        src={Illustration30DaysFree}
        alt="Extend discount"
        css={{ width: "120px", height: "120px" }}
      />
      <Box spaceBefore={[0, 1]} spaceAbove={[1, 0]}>
        <Text preset={TypePreset.Body_03} weight={FontWeight.SemiBold}>
          {headerText}
        </Text>
        <Space v={1} />
        <Text preset={TypePreset.Body_01}>{bodyText}</Text>
      </Box>
    </Box>
  );
};

export default ExtendDiscountBox;
